import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { dispatch } from "../index";
import { storage } from "../../utils/helpers/storage";
import {
  getDashboardsByAccess,
  sortDashboardsByName,
} from "../../utils/helpers/permissions";
import { getFilteredResultsByFilterName } from "./analytics.helpers";
import { FilterName } from "../../utils/constants/analytics";

// ----------------------------------------------------------------------

export const initialState = {
  error: null,
  allDashboards: [],
  availableDashboards: [],
  analyticsCategory: "",
  analyticsSection: "",
  dashboards: [],
  availableWarehouseInductAreas: [],
  availableWarehouseAngles: [],
  availableWarehouseCustowners: [],
  availableWarehouseFloors: [],
  availableWarehouseMeasureUnits: [],
  availableWarehouseOpenWorkStatus: [],
  availableWarehouseOrderTypes: [],
  availableWarehouseTaskTypes: [],
  availableWarehouseTaskZones: [],
  availableWarehouseWorkStatus: [],
  availableWorkAreaGrouping: [],
  selectedDashboard: storage.getSelectedDashboard()
    ? JSON.parse(storage.getSelectedDashboard())
    : null,
  selectedDashboardPages: [],
  selectedPage: "ReportSection",
  showReportSplash: true,
};

const slice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    getDashboardsSuccess(state, action) {
      state.dashboards = action.payload;
    },
    setAllDashboards(state, action) {
      state.allDashboards = action.payload;
    },
    setAvailableDashboards(state, action) {
      state.availableDashboards = action.payload;
    },
    setAnalyticsCategory(state, action) {
      state.analyticsCategory = action.payload;
    },
    setAnalyticsSection(state, action) {
      state.analyticsSection = action.payload;
    },
    setSelectedDashboard(state, action) {
      storage.setSelectedDashboard(JSON.stringify(action.payload) || null);
      state.selectedDashboard = action.payload;
    },
    setAvailableWarehouseCustowners(state, action) {
      state.availableWarehouseCustowners = action.payload;
    },
    setAvailableWarehouseFloors(state, action) {
      state.availableWarehouseFloors = action.payload;
    },
    setAvailableWarehouseMeasureUnits(state, action) {
      state.availableWarehouseMeasureUnits = action.payload;
    },
    setAvailableWarehouseAngles(state, action) {
      state.availableWarehouseAngles = action.payload;
    },
    setAvailableWarehouseTaskTypes(state, action) {
      state.availableWarehouseTaskTypes = action.payload;
    },
    setAvailableWarehouseWorkStatus(state, action) {
      state.availableWarehouseWorkStatus = action.payload;
    },
    setAvailableWorkAreaGrouping(state, action) {
      state.availableWorkAreaGrouping = action.payload;
    },
    setAvailableWarehouseOpenWorkStatus(state, action) {
      state.availableWarehouseOpenWorkStatus = action.payload;
    },
    setAvailableWarehouseOrderTypes(state, action) {
      state.availableWarehouseOrderTypes = action.payload;
    },
    setAvailableWarehouseTaskZones(state, action) {
      state.availableWarehouseTaskZones = action.payload;
    },
    setAvailableWarehouseInductAreas(state, action) {
      state.availableWarehouseInductAreas = action.payload;
    },
    setShowReportSplash(state, action) {
      state.showReportSplash = action.payload;
    },
    setSelectedDashboardPages(state, action) {
      state.selectedDashboardPages = action.payload;
    },
    setSelectedPage(state, action) {
      state.selectedPage = action.payload;
    },
    resetAnalyticsFilters(state) {
      state.availableWarehouseCustowners = initialState.availableWarehouseCustowners;
      state.availableWarehouseFloors = initialState.availableWarehouseFloors;
      state.availableWarehouseMeasureUnits = initialState.availableWarehouseMeasureUnits;
      state.availableWarehouseAngles = initialState.availableWarehouseAngles;
      state.availableWarehouseTaskTypes = initialState.availableWarehouseTaskTypes;
      state.availableWarehouseOpenWorkStatus = initialState.availableWarehouseOpenWorkStatus;
      state.availableWarehouseOrderTypes = initialState.availableWarehouseOrderTypes;
      state.availableWarehouseWorkStatus = initialState.availableWarehouseWorkStatus;
      state.availableWorkAreaGrouping = initialState.availableWorkAreaGrouping;
      state.availableWarehouseTaskZones = initialState.availableWarehouseTaskZones;
      state.availableWarehouseInductAreas = initialState.availableWarehouseInductAreas;
    },
    resetWarehouseFilters(state) {
      state.availableWarehouseCustowners = initialState.availableWarehouseCustowners;
      state.availableWarehouseFloors = initialState.availableWarehouseFloors;
      state.availableWarehouseTaskTypes = initialState.availableWarehouseTaskTypes;
      state.availableWarehouseTaskZones = initialState.availableWarehouseTaskZones;
      state.availableWorkAreaGrouping = initialState.availableWorkAreaGrouping;
      state.availableWarehouseInductAreas = initialState.availableWarehouseInductAreas;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllDashboards() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/analytics/analytics/dashboard`,
        {},
        {},
      );

      let _allDashboards = [];

      if (response?.data?.count > 0) {
        _allDashboards = response?.data?.rows
          .sort(sortDashboardsByName())
          .filter((row) => {
            return row.type === "dashboard";
          });
      }
      dispatch(slice.actions.setAllDashboards(_allDashboards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAnalyticsFilters(warehouse, filters) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/warehouse/filter`,
        {
          headers: {
            "locus-warehouse": warehouse,
          },
        },
      );

      // Check for filters in response?.data?.data any object keys
      if (
        !response?.data?.data ||
        Object.keys(response?.data?.data)?.length === 0
      ) {
        window.location.reload(); // Reload the page if there are no keys in the response data
      }

      dispatch(
        slice.actions.setAvailableWarehouseCustowners(
          getFilteredResultsByFilterName(
            FilterName.CUST_OWNER,
            response?.data?.data?.custOwners?.rows,
            filters,
          ),
        ),
      );

      dispatch(
        slice.actions.setAvailableWarehouseFloors(
          getFilteredResultsByFilterName(
            FilterName.FLOOR,
            response?.data?.data?.floors?.rows,
            filters,
          ),
        ),
      );

      dispatch(
        slice.actions.setAvailableWarehouseMeasureUnits(
          getFilteredResultsByFilterName(
            FilterName.UNIT_OF_MEASURE,
            response?.data?.data?.measureUnits?.rows,
            filters,
          ),
        ),
      );

      dispatch(
        slice.actions.setAvailableWarehouseAngles(
          getFilteredResultsByFilterName(
            FilterName.ROTATION,
            response?.data?.data?.angles?.rows,
            filters,
          ),
        ),
      );

      dispatch(
        slice.actions.setAvailableWarehouseTaskTypes(
          getFilteredResultsByFilterName(
            FilterName.TASK_TYPE,
            response?.data?.data?.taskTypes?.rows,
            filters,
          ),
        ),
      );
      dispatch(
        slice.actions.setAvailableWarehouseOpenWorkStatus(
          getFilteredResultsByFilterName(
            FilterName.OPEN_WORK_STATUS,
            response?.data?.data?.openWorkStatus?.rows,
            filters,
          ),
        ),
      );

      dispatch(
        slice.actions.setAvailableWarehouseOrderTypes(
          getFilteredResultsByFilterName(
            FilterName.ORDER_TYPE,
            response?.data?.data?.orderTypes?.rows,
            filters,
          ),
        ),
      );

      dispatch(
        slice.actions.setAvailableWarehouseWorkStatus(
          getFilteredResultsByFilterName(
            FilterName.WORK_STATUS,
            response?.data?.data?.workStatus?.rows,
            filters,
          ),
        ),
      );
      dispatch(
        slice.actions.setAvailableWorkAreaGrouping(
          getFilteredResultsByFilterName(
            FilterName.WORK_AREA_GROUPING,
            response?.data?.data?.workAreaGrouping?.rows,
            filters,
          ),
        ),
      );
      dispatch(
        slice.actions.setAvailableWarehouseTaskZones(
          getFilteredResultsByFilterName(
            FilterName.ZONE,
            response?.data?.data?.taskZones?.rows,
            filters,
          ),
        ),
      );

      dispatch(
        slice.actions.setAvailableWarehouseInductAreas(
          response?.data?.data?.inductAreas?.rows,
          getFilteredResultsByFilterName(
            FilterName.INDUCT_AREA,
            response?.data?.data?.inductAreas?.rows,
            filters,
          ),
        ),
      );
    } catch (error) {
      dispatch(slice.actions.resetAnalyticsFilters());
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWarehouseFilters(warehouse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/warehouse/filter`,
        {
          headers: {
            "locus-warehouse": warehouse,
          },
        },
      );

      dispatch(
        slice.actions.setAvailableWarehouseCustowners(
          response?.data?.data?.custOwners?.rows,
        ),
      );
      dispatch(
        slice.actions.setAvailableWarehouseFloors(
          response?.data?.data?.floors?.rows,
        ),
      );
      dispatch(
        slice.actions.setAvailableWarehouseTaskTypes(
          response?.data?.data?.taskTypes?.rows,
        ),
      );
      dispatch(
        slice.actions.setAvailableWarehouseTaskZones(
          response?.data?.data?.taskZones?.rows,
        ),
      );

      dispatch(
        slice.actions.setAvailableWorkAreaGrouping(
          response?.data?.data?.workAreaGrouping?.rows,
        ),
      );

      dispatch(
        slice.actions.setAvailableWarehouseInductAreas(
          response?.data?.data?.inductAreas?.rows,
        ),
      );
    } catch (error) {
      dispatch(slice.actions.resetWarehouseFilters());
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Function to check if filters exist for every row and section
const allHaveFilters = (rows) => {
  if (!rows || rows.length === 0) return false;

  // Ensure every row has filters and, if sections exist, every section has filters
  return rows.every((row) => {
    // Check if filters are present directly at the row level
    const rowHasFilters = row?.filters ? !!row?.filters?.length : true;

    // If sections exist, check if every section has filters
    const sectionsHaveFilters = row?.sections?.length
      ? row.sections.every((section) => section?.filters?.length > 0)
      : true; // If no sections, skip this check

    // Both the row and all sections (if any) must have filters
    return rowHasFilters && sectionsHaveFilters;
  });
};

export function getDashboards(
  analyticsCategory,
  analyticsSection,
  selectedSite,
) {
  return async () => {
    try {
      let params = {};
      if (analyticsCategory) {
        params.category = analyticsCategory;
      }
      if (analyticsSection) {
        analyticsSection =
          analyticsSection === "reports"
            ? "report"
            : analyticsSection === "dashboards"
              ? "dashboard"
              : null;
        params.type = analyticsSection;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API}/analytics/analytics/dashboard`,
        { params },
        {},
      );
      // Check for filters in all rows and sections
      const filtersExistEverywhere = allHaveFilters(response?.data?.rows);

      // If any row or section lacks filters, refresh the page
      if (!filtersExistEverywhere) {
        window.location.reload();
      }

      let _availableDashboards = [];
      if (selectedSite?.id && response?.data?.count > 0) {
        _availableDashboards = getDashboardsByAccess(
          response?.data,
          analyticsSection,
          selectedSite,
        );
        dispatch(slice.actions.setAnalyticsSection(analyticsSection));
      }
      dispatch(slice.actions.setAnalyticsCategory(analyticsCategory));
      dispatch(slice.actions.setAvailableDashboards(_availableDashboards));
      dispatch(slice.actions.getDashboardsSuccess(response.data));
      let currentSelectedDashboard = storage.getSelectedDashboard()
        ? JSON.parse(storage.getSelectedDashboard())
        : null;

      if (
        currentSelectedDashboard &&
        _availableDashboards &&
        _availableDashboards.some((e) => e?.id === currentSelectedDashboard?.id)
      ) {
        dispatch(
          slice.actions.setSelectedDashboard(
            _availableDashboards.find(
              (e) => e?.id === currentSelectedDashboard?.id,
            ),
          ),
        );
      }
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReportUrl(data) {
  return async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API}/analytics/analytics/report/${data.reportId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "locus-powerbi": data.powerBi,
            "locus-user": `{"username":"${data.userName}"}`,
            "locus-dataset-id": data.datasetId,
            "locus-warehouse": data.condensedName,
            "locus-client-id": data.clientId,
          },
        },
      );
      if (response?.data?.pages) {
        dispatch(
          slice.actions.setSelectedDashboardPages(response?.data?.pages),
        );
      } else {
        dispatch(slice.actions.setSelectedDashboardPages([]));
      }
      return response;
    } catch (error) {
      dispatch(slice.actions.setSelectedDashboardPages([]));
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function createDashboard(data) {
  return async () => {
    try {
      return data;
      // return axios.post(
      //     `${process.env.REACT_APP_SERVICES_API}/analytics/dashboard`,
      //     data,
      //     {}
      // );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function setSelectedDashboard(dashboard) {
  return async () => {
    try {
      dispatch(slice.actions.setSelectedDashboard(dashboard));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setShowReportSplash(value) {
  return async () => {
    try {
      dispatch(slice.actions.setShowReportSplash(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSelectedPage(value) {
  return async () => {
    try {
      dispatch(slice.actions.setSelectedPage(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
